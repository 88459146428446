import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/nodejs-introduction",
  "date": "2014-09-23",
  "title": "NODE.JS INTRODUCTION",
  "author": "admin",
  "tags": ["development", "javascript", "node.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "Node.js allows you to build scalable network applications using JavaScript on the server-side. It uses the V8 JavaScript Runtime. It's written in C which makes it fast."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`What could you build with Node.js?`}</h2>
    <ul>
      <li parentName="ul">{`Websocket Server (eg: chat server)`}</li>
      <li parentName="ul">{`File Upload Client`}</li>
      <li parentName="ul">{`Ad Server`}</li>
      <li parentName="ul">{`Any Real-Time Data Apps`}</li>
    </ul>
    <h2>{`What is Node.js Not?`}</h2>
    <ul>
      <li parentName="ul">{`Not a Web Framework`}</li>
      <li parentName="ul">{`It's not Multi-threaded. It's more of a single-threaded server.`}</li>
      <li parentName="ul">{`A Web Framework`}</li>
    </ul>
    <h2>{`Hello Node!`}</h2>
    <p>{`Let's start with a simple Hello server. First, tell the response which status it should have (a successful status is 200).
Next, write a message to the response body in the form of `}<inlineCode parentName="p">{`Hello, this is <your name here>`}</inlineCode>{`.
To finish it up, tell the response to end so the client on the other side knows it's received all the data.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var http = require('http');

http.createServer(function(request, response) {
  response.writeHead(200);
  response.write('Hello, this is Sean');
  response.end();
}).listen(8080);
`}</code></pre>
    <h2>{`Blocking Vs Non-Blocking Code`}</h2>
    <p>{`One of the biggest advantages of Node.js is the ability to use Non-blocking code. Let's write some blocking code followed by its non-blocking version.`}</p>
    <p><strong parentName="p">{`Blocking Code`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var fs = require('fs');
var contents = fs.readFileSync('/etc/hosts');
console.log(contents);
console.log('Doing something else');
`}</code></pre>
    <p>{`Here it stops the process until the read action is complete.`}</p>
    <p><strong parentName="p">{`Non-Blocking Code`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`fs.readFile('/etc/hosts', function(err, contents) {
    console.log(contents);
});
console.log('Doing something else');
`}</code></pre>
    <p>{`We can move our read action into a function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var callback = function(err, contents) {
    console.log(contents);
}
fs.readFile('/etc/hosts', callback);
`}</code></pre>
    <h2>{`Running your Code`}</h2>
    <p>{`Install Node.js in your website.
Now go ahead and run that file we just created to read a file of the filesystem with node app.js`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`node app.js
`}</code></pre>
    <h2>{`Read File in Server`}</h2>
    <p>{`Now that you know how to create an HTTP server and how to read a file of the filesystem in a non-blocking way, let's try to combine the two.
Instead of just writing a string to the HTTP response, use `}<inlineCode parentName="p">{`fs.readFile`}</inlineCode>{` to write the contents of `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` to the response instead.
Note: since fs.readFile is non-blocking, make sure you end the response inside readFile's callback.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var http = require('http');
var fs = require('fs');
http.createServer(function(request, response) {
  response.writeHead(200);
  fs.readFile('index.html', function(err, contens) {
    response.write(contens);
    response.end();
  });
}).listen(8080);
`}</code></pre>
    <h2>{`Issuing a Request`}</h2>
    <p>{`Let's see our new server in action. We've already run node app.js, so in the terminal below use curl to issue a request to http://localhost:8080 and we'll see our server respond with the contents of index.html`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`curl http://localhost:8080
`}</code></pre>
    <h2>{`Response WriteHead`}</h2>
    <p>{`Up until now all we've been sending into the writeHead function is the status code. However, it can take additional parameters. Let's add 'Content-Type' of 'text/html' to the response.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var http = require('http');
var fs = require('fs');

http.createServer(function(request, response) {
  response.writeHead(200, {'Content-Type': 'text/html'} );

  fs.readFile('index.html', function(err, contents) {
    response.write(contents);
    response.end();
  });
  
}).listen(8080);
`}</code></pre>
    <h2>{`Response End`}</h2>
    <p>{`Our original Hello server can be shortened since the response.end() function optionally takes data as a parameter. Remove the 'response.write' line altogether, and send the hello string as a parameter on the response.end function. This will send the data, and once finished add the end to the response.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var http = require('http');

http.createServer(function(request, response) {
  response.writeHead(200);
  response.end("Hello, this is dog");
}).listen(8080);
`}</code></pre>
    <h2>{`The Event Loop`}</h2>
    <p>{`The event loop manages events by processing one at a time from the `}<strong parentName="p">{`Event Queue`}</strong>{`. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      